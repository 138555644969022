import React from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"

import Denizen from "../../types/Denizen"
import { Creature } from "../../types/Creature"

import Image from "gatsby-plugin-sanity-image"
import Layout from "../../components/layout"
import CommonCreaturePage from "./creature"

interface DataProps {
  data: {
    denizen: Denizen
    creature: Creature
  }
}

const DenizenPage: React.FC<DataProps> = ({ data }) => {
  // console.log("dataaaaaa")
  return (
    <Layout>
      <div className="bg-texture block">
        <div className="max-w-4xl mx-auto px-6 sm:px-0 bg-title">
          <div className="block mt-12 titleStyle">
            <h1 className="text-mocha text-5xl font-display font-bold text-center">
              {data.denizen.name}
            </h1>
          </div>
          <div className="sm:float-right sm:h-112 classImage">
            <Image
              {...data.denizen.image}
              alt={`${data.denizen.name} Avatar`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>

          <div className="max-w-none mx-auto prose font-body text-fontSize leading-relaxed font-medium">
            <BlockContent blocks={data.denizen._rawFlavorText} />
          </div>

          <CommonCreaturePage data={data} />
        </div>
      </div>
    </Layout>
  )
}

export default DenizenPage

export const query = graphql`
  query($id: String!, $name: String!) {
    denizen: sanityDenizen(id: { eq: $id }) {
      id
      name
      _rawFlavorText
      image {
        ...ImageWithPreview
      }
    }
    creature: allSanityCreature(
      filter: { type: { elemMatch: { name: { eq: $name } } } }
    ) {
      nodes {
        strength
        stealth
        stamina
        speed
        special_feat
        size
        reach
        perception
        name
        might
        marksmanship
        lifespan
        id
        hit_points
        guile
        fortitude
        fate
        dexterity
        defense
        courage
        common_level
        charisma
        attack
        agility
        lore_points
        type {
          name
        }
        _rawFlavorText
        image {
          ...ImageWithPreview
        }
        will
        intelligence
        _rawLore
        _rawSpells
        _rawOther
        _rawModifiers
        _rawDetails
        summary_flavor_text
      }
    }
  }
`
